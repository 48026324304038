$primary-color: #007bff;
$background-color: #f8f9fa;
$box-shadow-color: rgba(0, 0, 0, 0.1);

.stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .step {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;

        .step-content {
            display: flex;
            align-items: center;

            .step-icon {
                background-color: $primary-color;
                color: #fff;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: bold;
                margin-right: 15px;
            }

            .step-info {
                background-color: $background-color;
                padding: 10px 15px;
                border-radius: 5px;
                box-shadow: 0 1px 3px $box-shadow-color;

                h5 {
                    margin-top: 0;
                    margin-bottom: 5px;
                }

                p {
                    margin: 0;
                }
            }
        }

        .step-connector {
            width: 2px;
            height: 30px;
            background-color: $primary-color;
            position: absolute;
            left: 15px;
            top: 30px;
        }
    }
}