.collections-modal {
    .hide-accordion-arrow {
        .accordion-button {
            padding-top: 10px !important;
            padding-bottom: 10px !important;

            &::after {
                display: none !important;
            }
        }
    }
}