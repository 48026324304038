.reward-type-form {
    .full-width {
        width: 100%;
    }

    .half-width {
        width: 50%;
    }

    .trash-icon-container {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: red;
    }

    .image-100 {
        width: 100%;
        height: 100%;
    }
}