.customBox {
  .box {
    width: 180px;
    height: 180px;
    background-color: #fff;
    // border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
  }

  .icon {
    font-size: 48px;
    // color: #3498db;
    margin-bottom: 3px;
  }

  .content h3 {
    font-size: 0.8125rem;
    margin-bottom: 5px;
  }

  .content p {
    font-size: 0.7125rem;
    color: #777;
    white-space: normal;
  }
  .card-radio .form-check-input:checked + .form-check-label:before {
    color: #45cb85;
    font-size: 24px;
  }
  .card-radio .form-check-input:checked + .form-check-label {
    border: 0.8px solid #45cb85 !important;
  }
}
