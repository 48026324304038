@use 'sass:color';

a,
.nav-link {
    color: var(--vz-gray-700);
}

.nav-link {
    &:hover {
        color: var(--vz-primary);
    }
}

.svg-font-color svg>path {
    fill: var(--ifm-font-color-base);
}

.queryBuilder {
    min-width: 420px;
}

.ruleGroup-header {
    position: relative;
    margin: -0.5rem -0.5rem 0rem -0.5rem;
    padding: 0.5rem;
    border-bottom: 0px solid var(--vz-border-color);
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
}

.ruleGroup-body {
    gap: 1rem;

    .ruleGroup-header {
        position: relative;
        padding: 0px 0.5rem;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
}

.rulePopup {
    >div {
        display: flex;
        gap: 1rem;
    }
}

// Styles for when "Use validation" option is selected
.validateQuery {
    .queryBuilder {

        // Invalid groups
        .ruleGroup.queryBuilder-invalid {
            background-color: #66339966; // transluscent rebeccapurple

            // Bold the + Rule button if the group has no child rules or groups
            .ruleGroup-addRule {
                font-weight: bold !important;
            }

            // Message to user about empty groups
            &>.ruleGroup-header {
                position: relative;

                &::after {
                    content: 'Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.';
                    color: white;
                }
            }
        }

        // Invalid rules
        .rule.queryBuilder-invalid {

            // Purple background for empty text fields
            .rule-value {
                background-color: #66339966; // transluscent rebeccapurple

                &::placeholder {
                    color: color.scale(rebeccapurple, $lightness: -30%);
                }
            }
        }
    }
}

// Styles for when "Use validation" option is selected (dark theme variations)
html[data-theme='dark'] {
    .validateQuery {
        .queryBuilder {
            .rule.queryBuilder-invalid {
                .rule-value {
                    &::placeholder {
                        color: color.scale(rebeccapurple, $lightness: 30%);
                    }
                }
            }
        }
    }
}

// Styles for when "Justified layout" option is selected
.justifiedLayout {
    .queryBuilder {

        // Push the clone, lock, and remove buttons to the right edge
        .ruleGroup-addGroup {

            &+button.ruleGroup-cloneGroup,
            &+button.ruleGroup-lock,
            &+button.ruleGroup-remove {
                margin-left: auto !important;
            }
        }

        .rule-operators,
        .rule-value,
        .control,
        .chakra-select__wrapper {

            &+button.rule-cloneRule,
            &+button.rule-lock,
            &+button.rule-remove {
                margin-left: auto !important;
            }
        }
    }
}

// Custom Style
.queryBuilder {

    .form-control,
    .form-select,
    .input {
        width: auto;
    }

    .form-select-sm {
        padding: 0.5rem 2.5rem 0.5rem 0.9rem !important;
        font-size: 0.8125rem;
    }

    .form-control-sm {
        padding: 0.5rem 0.9rem;
        font-size: 0.8125rem;

        &.form-check-input {
            height: 36px;
            width: 36px;
            margin: 0px;
            background-size: 50%;

            &[type='checkbox'] {
                --vz-form-check-bg-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e');
                background-color: #d9d9d9;

                &:checked {
                    background-color: #3577f1;
                }
            }
        }
    }

    .rule-value {
        display: flex;
        gap: 1rem;

        .form-check-inline {
            margin-right: 0;
        }
    }

    .btn-sm {
        padding: 0.5rem 0.7rem;
        font-size: 0.8125rem;
    }

    .form-check-inline {
        display: flex;
        margin-right: 1rem;
        gap: 8px;
    }

    &.queryBuilder-branches {
        .rule {

            &::before,
            &::after {
                border-color: var(--vz-border-color);
            }
        }

        .ruleGroup {
            border-color: var(--vz-border-color);
            background: #fff;

            // padding-top: 0.2rem !important;
            // padding-bottom: 0.2rem !important;
            .ruleGroup {
                background: rgba(255, 255, 255, 1);
                border-color: var(--vz-border-color);

                &::before,
                &::after {
                    left: calc(-0.5rem - 1px) !important;
                    border-color: var(--vz-border-color);
                }
            }
        }
    }

    .ruleGroup-addGroup {
        &.btn-primary {
            background-color: var(--vz-gray-600);
            border-color: var(--vz-gray-600);

            &:hover {
                background-color: var(--vz-gray-700);
                border-color: var(--vz-gray-700);
            }
        }
    }

    .rule-cloneRule,
    .ruleGroup-lock,
    .ruleGroup-cloneGroup,
    .rule-lock {
        padding: 0.32rem 0.6rem;

        i {
            font-size: 16px;
        }

        &.btn-primary {
            background-color: var(--vz-input-bg);
            border-color: var(--vz-input-border);
            color: var(--vz-gray-400);

            &:hover {
                background-color: var(--vz-input-bg);
                border-color: var(--vz-input-border);
                color: var(--vz-gray-700);
            }
        }
    }

    // .queryBuilder-disabled {
    //   .rule-lock {
    //     background-color: var(--vz-gray-600);
    //     border-color: var(--vz-gray-600);
    //     color: var(--vz-white);
    //     &:hover {
    //       background-color: var(--vz-gray-700);
    //       border-color: var(--vz-gray-700);
    //       color: var(--vz-white);
    //     }
    //   }
    // }
    .rule-remove,
    .ruleGroup-remove {
        padding: 0.18rem 0.4rem;
        background: transparent;
        border: none;
        color: var(--vz-gray-600);
        box-shadow: none;
        opacity: 0.7;

        &:disabled {
            background: transparent;
            border: none;
            color: var(--vz-gray-400);
        }

        &:hover {
            opacity: 1;
            background: transparent;
            color: var(--vz-danger);
        }

        i {
            font-size: 20px;
        }
    }
}

//
.show-on-hover {
    display: none;
}

.trigger-hover:hover .show-on-hover {
    display: inline;
}

.rule-remove {
    padding: 0.18rem 0.4rem;
    background: transparent;
    border: none;
    color: var(--vz-gray-600);
    box-shadow: none;
    opacity: 0.7;

    &:hover {
        opacity: 1;
        background: transparent;
        color: var(--vz-danger);
    }
}

.rulesEffects {
    input {
        &.form-control {
            &[type='number'] {
                width: 100%;
                max-width: 120px;
            }
        }
    }
}

.bg-rule {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
}

.custom-space {
    padding: 2px 0;
}