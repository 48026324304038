.combos-modal {
    .combo-list {
        min-height: 60vh;
        max-height: 60vh;
        overflow: scroll;
    }

    .hide-accordion-arrow {
        .accordion-button {
            padding-top: 10px !important;
            padding-bottom: 10px !important;

            &::after {
                display: none !important;
            }
        }
    }

    .accordion-item-border-none {
        .accordion-item {
            border: none;
        }
    }
}