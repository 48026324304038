// flag input

[data-input-flag] {
  position: relative;

  .dropdown-menu {
    .dropdown-menu-list {
      max-height: 158px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 8px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--#{$prefix}dark-rgb), 0.075);
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px;
      }
    }
  }
}

[data-option-flag-img-name] {
  .flag-input {
    padding-left: 45px;
    background-image: url('../../images/flags/ar.svg');
    background-repeat: no-repeat;
    background-position: 14px 8px;
    background-size: 18px;
  }
}

[data-option-flag-name],
[data-option-flag-img-name] {
  &::before {
    content: '\f0140';
    font-family: 'Material Design Icons';
    position: absolute;
    right: $input-padding-x;
    top: $input-padding-y;
    font-size: 16px;
  }
}

[data-option-countrycode='false'] {
  .country-codeno {
    display: none;
  }
}

[data-search-input='false'] {
  .searchlist-input {
    display: none;
  }
}
