//
// _page-head.scss
//

.page-title-box {
  padding: 10px $grid-gutter-width;
  background-color: var(--#{$prefix}card-bg-custom);
  box-shadow: $page-title-box-shadow;
  border-bottom: 1px solid $page-title-border;
  margin: -23px (-$grid-gutter-width * 1) $grid-gutter-width
    (-$grid-gutter-width * 1);

  .breadcrumb {
    background-color: transparent;
    padding: 0;
  }
}

[data-layout="horizontal"] {
  .page-title-box {
    padding: 1.2rem 0;
    background-color: transparent !important;
    border-bottom: none;
    border-top: none;
    box-shadow: none;
    margin: 0;

    @media (min-width: 1024.1px) {
      margin: -19px 0 0 0;
    }
  }
}

[data-layout="vertical"] {
  &[data-layout-style="detached"] {
    .page-title-box {
      padding: 1.2rem 0;
      background-color: transparent !important;
      border-bottom: none;
      border-top: none;
      box-shadow: none;
      margin: 0;

      @media (min-width: 1024.1px) {
        margin: -19px 0 0 0;
      }
    }
  }
}
