.timeline-2 {
  overflow: hidden;
  &::after {
    background-color: var(--vz-light);
    height: 100%;
  }
  .timeline-date-custom {
    font-size: 14px;
    font-weight: 500;
    margin: 16px 0 8px 33px;
    padding-left: 28px;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      top: 50%;
      margin-top: -6px;
      left: 1px;
      background: var(--vz-card-bg-custom);
      border: 2px solid var(--vz-primary);
      box-shadow: var(--vz-box-shadow) !important;
      border-radius: 50px;
      z-index: 1;
    }
  }
  .timeline-launch {
    z-index: 1;
  }
  .timeline-box {
    margin-bottom: 0px;
  }
  .timeline-box-custom {
    padding: 0px;
    margin: 10px 63px;
    position: relative;
  }
  .timeline-year {
    p {
      padding: 23px 16px;
    }
  }
}
.apexcharts-legend-text {
  font-size: 11px !important;
}
