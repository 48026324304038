@import '../scss/icons.scss';
@import '../scss/config/material/bootstrap.scss';
@import '../scss/config/material/app.scss';
@import '../scss/config/material/custom.scss';
@import './shepherd.css';
@import './mermaid.min.css';

// @import "./components/demos.scss";

//RTL
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";

//Rigthsidebar offcanvas
.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  * {
    outline: none;
  }
}

.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}

.noUi-connect,
.noUi-horizontal {
  height: 2px !important;
  background: #4b38b3 !important;
}

.noUi-handle {
  background: #4b38b3 !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}

.dropzone .dz-message {
  text-align: center;
}

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

.fc-event {
  border: none !important;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

.flatpickr-calendar.open {
  z-index: 1090 !important;
}

.upcoming-scheduled .flatpickr-input[readonly] {
  display: none;
}

.ql-container.ql-snow {
  height: 250px;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #405189 !important;
}

// .Toastify__toast{
//   min-height: 43px !important;
//   max-height: 43px !important;
// }

@each $color, $value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before {
      background: $value !important;
    }
  }
}

// chat
.chat-conversation .chat-list .message-box-drop {
  .nav-btn {
    font-size: 18px;
    padding: 4px;
    color: $gray-600;
  }
}

.chatwidgets {
  height: 250px !important;
}

[data-layout='horizontal'] .chat-conversation {
  &.chatwidgets {
    height: 250px !important;
  }
}

// authentication

#tsparticles {
  canvas {
    position: absolute !important;
  }
}

.alert-msg-show {
  right: 24px;
  top: 40px;
  z-index: 1005;
}

// Emoji Picker

.pickerEmoji {
  position: absolute;
  bottom: -50px;
  left: 10%;
  transform: translateX(-50%);
}

// Toast
.Toastify {
  .Toastify__toast {
    min-height: 43px;
  }

  .Toastify__close-button {
    top: 6px;
  }

  .Toastify__close-button--light {
    color: $light;
  }
}

// Tranfer List

.rdl-filter,
.rdl-control {
  background-color: $input-bg;
  border: 1px solid $input-border-color !important;
  color: $input-color !important;
}

.rdl-move {
  background: var(--#{$prefix}light) !important;
  border: 1px solid var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

.react-dual-listbox {
  input:disabled,
  select:disabled {
    background-color: $input-disabled-bg !important;
    border: 1px solid $input-border-color !important;
  }
}

.css-26l3qy-menu {
  background-color: var(--#{$prefix}gray-200) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

.css-1n7v3ny-option {
  background-color: var(--#{$prefix}gray-300) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

// body {
//   cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADj0lEQVR4nO2YS08TURTH6wdw5RITNz4JBYpTReLORyIL0qI8bQeSFomSiGUnuwIaFOpjIwrRRC32OWUmLQIJKDqCX8Iu3CmRxLISiR5zBtreGRo6M+10ajInOUkz03vm/7v3nHvPjMlkmGGGGfbfm+147IC9KhC1mYNJOW43B5abKsOVpvIw2GerCszbzUFQ4jZz4GvTMXa/5vLuXOO5u72fQOIz6fv2qmCvUvFZiOBj7QF6+b9SALyG95pP+A/ZzcFUAQB/bNXhs0UXffUUc9Bp5SJOitugrRzo6U5BA8t0n44fkS+e4tb1Fk7vBlmn6+MVeQFw5qWD+xsXYMjFw7CLh5uXFnSEYEP5AXKkzYibz+T/sJvXD8DK/cwLkGtgf+O8MPtDro/Qv8cKOCkWOiwMXKkJw+XqEDSbtx1/47X2OgYcFFsQhEkNgIylhbbaqOzdp6U2ohrEVGyAzrqYqi20uToInSdj+gJgSkiF9V1cBl9PEvyDGxAb2RLcP5iCcXcS+i683/X/dgujD4B05rsbZmFqYA3io7CnT3nWoKthVjS2U8FKFAUAc54UcP3cEkSHNvOKTzv+F8fYiXRyWNnSAbTWRkQzn1P8PYDZMYA5H0Difg4I7yZ0NSQycTBmSQCksz9567tIGIp99wxgZRrgc2DH3wB8eAEw/0iSTgNrolgYW3MALDqyYElBONsrfkJ4Dl+cEEP0EYXdUcdoD9BSk00fnzspEsO/zArlXwEsPQVYfAKw/Hx7FdL35h5mx4y5v2Ti4WGnOQCequkH4lZJAqDQ1QDA0gRAQpLzcw+2V2d1GuCtL3vdP5jKxMPYmgOQOTszsiXO/50akLsbxUdBiEHG1BVAjTPDv4ntNKRvCqnx17fJFAqXuIh7xEWsxsddJS5isv/BLbBQgBvns9soxtYcwCE9yDzfVIuf8uhwkKG3WrK9P30mDhHvL8XidWsl6J12AhswUTPnVdDMeaXNXEjW7BcNAB1bYHL5sUWe9Ij7olyOvRM583ahnZ6R9cyiAkj7IrKwsT3A7RHPCXT8jdfIgs30PxadXmjIlSDTSf4rZUh4KVL6vKIDCDVhZaHNouSlPio75+lSAJDFjS0xHnZ4qpInLB5SeE+tcFo2AMWmCnlAGXzYYpkyBojkBXBaY0dpivuht1h6V3py6w4LdzgvgLAK9fEK/JBaFulEsSmcedniDTPMMMNMpbR/VjfBPK/uH7cAAAAASUVORK5CYII'),
//     auto;
// }
// .silver,
// .platinum,
// .gold {
//   text-shadow: 1px 1px 0 #333;
// }
.silver {
  background: transparent
    linear-gradient(
      113deg,
      #c7c7c7 0%,
      #d1d1d1 17%,
      #f7f7f7 50%,
      #c7c7c7 81%,
      #cecece 100%
    )
    0% 0% no-repeat padding-box;
}
.platinum {
  background: transparent
    linear-gradient(
      113deg,
      #acc1d5 0%,
      #d6e4f4 27%,
      #fafeff 62%,
      #c4d9e6 82%,
      #acc1d5 100%
    )
    0% 0% no-repeat padding-box;
}
.gold {
  background: transparent
    linear-gradient(
      113deg,
      #e0a953 0%,
      #ecc766 27%,
      #eece6d 41%,
      #f9ea8d 67%,
      #c7953b 100%
    )
    0% 0% no-repeat padding-box;
}
.invalid-feedback {
  color: red;
}
.form-control:invalid,
.form-control.is-invalid {
  border-color: red;
}
.mediaUpload {
  .filepond--root {
    margin-bottom: 0px !important;
  }
  .filepond--drop-label label {
    font-size: 12px !important;
  }
  .filepond--panel-root {
    border: none !important;
  }
}
.tableFixHead {
  overflow-y: auto;
  max-height: calc(100vh - var(--vz-modal-margin) * 6);
  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 9;
    }
    th {
      &:first-child {
        left: 0;
        z-index: 99;
      }
    }
  }
  tbody {
    th {
      position: sticky;
      left: 0;
      z-index: 1;
      font-weight: 300;
    }
    tr {
      &:last-child {
        td,
        th {
          border: none;
        }
      }
    }
  }
}
